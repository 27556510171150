import React from "react";
import { isDev } from '../../helpers/environmentDetector';

export default class HeroVideoEmbed extends React.Component { 
    constructor(props) {
        super(props);
        console.log("Hero video embed is ready!");
    }

    render () {
        return (
<div
          className="row bg-blue-dark fill-screen-height"
          style={{ margin: "0px" }}
        >
          <div className="herocover">
            <div
              id="backgroundvideo"
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "black"
              }}
            >

              <div className="embed-container">
                {isDev() ? (
                  <span>
                    <span id="ppvrVideo" style={{width:"100%"}}>
                      This app is running in development mode right now.
                    </span>
                </span>
                ) : (
                  <span>
                    <iframe
                      id="ppvrVideo"
                      src="https://www.youtube-nocookie.com/embed/XTjMiBAlKGg?autoplay=1&amp;mute=1&amp;loop=1&amp;color=333463&amp;title=0&amp;byline=0&amp;portrait=0&amp;playlist=XTjMiBAlKGg"
                      width="100%"
                      frameBorder="0"
                      webkitallowfullscreen=""
                      mozallowfullscreen=""
                      allowFullScreen=""
                      volume="0"
                      title="Planet Protector VR trailer"
                    ></iframe>
                  </span>
                )}
              </div>

            </div>
          </div>
        </div>

        );
    }

}