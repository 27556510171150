import React from "react";
import SocialButton from "./SocialButton";


export default class PresskitContact extends React.Component {
  constructor(props) {
    super(props);
    console.log("Presskit & contact chunk ready!");
  }

  render() {
    return (
      <div className="row bg-blue-dark fill-screen-height">
        <h1 style={{ textAlign: "center", paddingTop: "3%" }}>Contact & Resources</h1>
          {/* --- */}
          <div className="col halfpage-column" style={{minHeight: "75%"}}>
            <h3 style={{ textAlign: "center" }}>Social Media</h3>
            <div className="buttonListContainer">
              <SocialButton buttonTitle="LINKEDIN" buttonLink="https://www.linkedin.com/company/bigfoot-design-studios/" />
              <SocialButton buttonTitle="FACEBOOK" buttonLink="https://www.facebook.com/BigfootDS/" />
              <SocialButton buttonTitle="INSTAGRAM" buttonLink="https://instagram.com/BigfootDS" />
              <SocialButton buttonTitle="TWITTER" buttonLink="https://twitter.com/BigfootDS" />
              <SocialButton buttonTitle="YOUTUBE" buttonLink="https://www.youtube.com/channel/UC5dK-u9Ar91WPphpur_MYMA/" />
              <SocialButton buttonTitle="EMAIL" buttonLink="mailto:contact@bigfootds.com?subject=I found your website!&body=Hey BigfootDS team!" />
            </div>
          </div>
          {/* --- */}
          <div className="col halfpage-column" style={{minHeight: "75%"}}>
            <h3 style={{ textAlign: "center" }}>Images & Assets</h3>
            <div className="buttonListContainer">
              <p>Download the BigfootDS logo & branding kit here:</p>
              <SocialButton buttonTitle="General Mediakit" buttonLink="https://bigfootds.s3.us-east-2.amazonaws.com/Presskits/BigfootDS-LogoBranding.zip" />
              <p>Download the Planet Protector VR logo & game images kit here:</p>
              <SocialButton buttonTitle="Planet Protector VR Mediakit" buttonLink="https://bigfootds.s3.us-east-2.amazonaws.com/Presskits/PPVR-LogoBranding.zip" />
              <div className="spacerDiv" style={{"minHeight":"10vh"}}>

              </div>
            </div>
          </div>
          {/* --- */}
          

      </div>
    );
  }
}
