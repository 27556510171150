import React from "react";

export default function Navbar(props) {
  return (

      <div
        style={{
          backgroundColor: "var(--bds-blue-light)",
          height: "10vh",
          minHeight: "100%",
        }}
      >
        <span className="valign-wrapper" style={{ height: "100%" }}>
          <button
            type="button"
            id="sidebarCollapse"
            className="btn blue lighten-2 bg-blue-even-lighter"
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
              textAlign: "left",
              padding: "0px"
            }}
            aria-label="sidebar toggle"
          >
            <img
              style={{ maxWidth: "100%", height:"100%", padding: "10px" }}
              src="/images/menu-button-of-three-horizontal-lines.png"
              alt="menu icon"
            ></img>
          </button>
          <img 
            src="/images/BigfootDS-LogoBranding/BigfootDSHeader_White_512.png" 
            alt="BigfootDS company logo and name" 
            className="companyHeaderImg"
          />
        </span>
      </div>
  );
}
