import React from "react";
// import { slide as Menu } from "react-burger-menu";
// import Navbar from "./pieces/Navbar";
import HeroVideoEmbed from './pieces/HeroVideoEmbed';
import PresskitContact from "./pieces/PresskitContact";
import AboutArticle from "./pieces/AboutArticle";

class PublicHome extends React.Component {
  constructor(props) {
    super(props);
    console.log("app ready!");
  }

  showSettings = event => {
    console.log("button clicked");
    event.preventDefault();
  };

  render() {
    return (
      <span>
        <HeroVideoEmbed />
        <AboutArticle />
        {/* <ThreeColumnAbout /> */}
        <PresskitContact />
        
      </span>
    );
  }
}

export { PublicHome };
