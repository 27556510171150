import React from 'react';

export default function SkillCell (props) {
    return (
        <div className="col skillsCol">
            <div className="row">
                <div>
                    <img 
                        src={props.cellImage}
                        style={{"maxHeight":"10vw", "maxWidth":"7.5vh"}}
                        alt="The logo of the skill labelled below."
                    />
                </div>
            </div>
            <div className="row">
                <h6>{props.cellHeading}</h6>
            </div>
        </div>
    )
}