import React from "react";
import { Link } from "react-router-dom";
import { isDev } from "../helpers/environmentDetector";
import { useAuth } from '../firebaseAccessor';
import SignUpEmailPass from './pieces/SignUpForm';
import { install } from '../pwaHelpers';
import AccountWidget from './pieces/AccountWidget';
function SideNav() {


	return (
		<nav id="sidebar" className="active">
			{!isDev() ? (
				<span>
					<p>More features coming soon!</p>
				</span>
			) : (
					<span>
						{/* // ACCOUNT */}

						<AccountWidget />

						{/* QUICK ACCESS BUTTONS */}
						<ul className="list-unstyled CTAs">
							{/* <p>Dummy Heading</p> */}

							<li>
								<Link to="/" className="download">
									<div style={{ width: "100%" }}>Home</div>
								</Link>
							</li>

							<li>

								<Link to="/about" className="download">
									<div style={{ width: "100%" }}>About</div>
								</Link>
							</li>
							<li>
								<Link to="/forums" className="download">
									<div style={{ width: "100%" }}>Forums</div>
								</Link>
							</li>
							<li>
								<Link to="/support" className="download">
									<div style={{ width: "100%" }}>Support</div>
								</Link>
							</li>

							<li>
								{/* <a href="https://bootstrapious.com/tutorial/files/sidebar.zip" className="download">Presskit</a> */}
								<Link to="/presskit" className="download">
									Presskit
              					</Link>
							</li>
							<li>
								{/* <a href="/dev" className="article">Developer Site</a> */}
								<Link to="/dev" className="download">
									Developer Site
              					</Link>
							</li>
							<li>
								<Link to="" className="article" onClick={install}>
									Install
              					</Link>
							</li>
						</ul>
					</span>
				)}
		</nav>
	);
}

export default SideNav;
