import React from 'react';
import adobeillustratorLogo from '../../assets/skillsLogos/adobeillustrator.png';
import adobephotoshopLogo from '../../assets/skillsLogos/adobephotoshop.png';
import adobepremiereLogo from '../../assets/skillsLogos/adobepremierepro.png';
import autodesk3DSmaxLogo from '../../assets/skillsLogos/autodesk3dsmax.png';
import autodeskmayaLogo from '../../assets/skillsLogos/autodeskmaya.png';
import awsLogo from '../../assets/skillsLogos/AWS.png';
import csharpLogo from '../../assets/skillsLogos/csharp.png';
import css3Logo from '../../assets/skillsLogos/css3.png';
import githubLogo from '../../assets/skillsLogos/github.png';
import googlecloudplatformLogo from '../../assets/skillsLogos/googlecloudplatform.png';
import googlefirebaseLogo from '../../assets/skillsLogos/googlefirebase.png';
import googlesuiteLogo from '../../assets/skillsLogos/googlesuite.png';
import hacknplanLogo from '../../assets/skillsLogos/hacknplan.png';
import html5Logo from '../../assets/skillsLogos/html5.png';
import javascriptLogo from '../../assets/skillsLogos/javascript.png';
import microsoftazureLogo from '../../assets/skillsLogos/Microsoft_Azure_Logo2017.png';
import nodeLogo from '../../assets/skillsLogos/node.png';
import quixelmixerLogo from '../../assets/skillsLogos/quixelmixer.png';
import reactLogo from '../../assets/skillsLogos/react.png';
import rubyLogo from '../../assets/skillsLogos/ruby.png';
import rubyonrailsLogo from '../../assets/skillsLogos/rubyonrails.png';
import trelloLogo from '../../assets/skillsLogos/trello.png';
// import unityLogo from '../../assets/skillsLogos/unity.png';
import unityFullLogo from '../../assets/skillsLogos/unityfull.png';
import vuforiaLogo from '../../assets/skillsLogos/vuforia.png';
import zbrushLogo from '../../assets/skillsLogos/zbrush.png';
import SkillCell from './skillCell';

export default function AboutArticle () {

    return (
        <div className="aboutArticleWrapper row bg-blue-dark fill-screen-height">

            <div className="col s12 m4 l2"> 
                {/* Spacer column */}
            </div>
            <div className="col s12 m4 l8">
                <h1 style={{ textAlign: "center", paddingTop: "3%" }}>About BigfootDS</h1>
                <div className="aboutContent-leadin">
                    <p>BigfootDS (or Bigfoot Design Studios) is an Australian-made software development studio.</p>
                    <p>...</p>
                    <p>It's actually just one dude - a guy named Alex!</p>
                </div>
                <div className="aboutContent bg-blue-normal" >
                    <p>BigfootDS was created (unofficially) as early as 2012, performing graphic design on the Sunshine Coast of Queensland, Australia.</p>
                    <p>After a while, BigfootDS was created officially in 2015 as a game development studio in Sydney of New South Wales, Australia.</p>
                    <p>Through the Academy of Interactive Entertainment's management program, Alex created & released Planet Protector VR for virtual reality devices in 2017 as an early-access title. Work is periodically ongoing with various corporate partners to put the game into arcades, new headsets, and more distribution platforms.</p>
                    <p>Alex's day-job as an educator keeps him busy - and keeps him learning! It's 2020 now, and BigfootDS can cover a wide range of projects with a wide range of skills. Check out the skills, tech & tools we cover below:</p>
                </div>
                <div className="row">
                    
                </div>
                <div className="row">
                    <div className="row" style={{"textAlign":"center"}}>
                        <h5>Games, VR, AR, & Software</h5>
                    </div>
                    <div className="row bg-blue-normal skillsRow">
                        <SkillCell
                            cellImage = {unityFullLogo}
                            cellHeading = "Unity"
                        />
                        <SkillCell
                            cellImage = {vuforiaLogo}
                            cellHeading = "Vuforia"
                        />
                    </div>

                    <div className="row" style={{"textAlign":"center"}}>
                        <h5>Websites, Web Apps, & Cloud</h5>
                    </div>
                    <div className="row bg-blue-normal skillsRow">
                        <SkillCell
                            cellImage = {googlecloudplatformLogo}
                            cellHeading = "Google Cloud Platform"
                        />
                        <SkillCell
                            cellImage = {googlefirebaseLogo}
                            cellHeading = "Google Firebase"
                        />
                        <SkillCell
                            cellImage = {awsLogo}
                            cellHeading = "Amazon Web Services"
                        />
                        <SkillCell
                            cellImage = {microsoftazureLogo}
                            cellHeading = "Microsoft Azure PlayFab"
                        />
                    </div>

                    <div className="row" style={{"textAlign":"center"}}>
                        <h5>3D Design & Animation</h5>
                    </div>
                    <div className="row bg-blue-normal skillsRow">
                    <SkillCell
                            cellImage = {zbrushLogo}
                            cellHeading = "Zbrush"
                        />
                        <SkillCell
                            cellImage = {autodesk3DSmaxLogo}
                            cellHeading = "Autodesk 3DS Max"
                        />
                        <SkillCell
                            cellImage = {autodeskmayaLogo}
                            cellHeading = "Autodesk Maya"
                        />
                        <SkillCell
                            cellImage = {quixelmixerLogo}
                            cellHeading = "Quixel Mixer"
                        />
                    </div>

                    <div className="row" style={{"textAlign":"center"}}>
                        <h5>Art, Illustration & Video</h5>
                    </div>
                    <div className="row bg-blue-normal skillsRow">
                    <SkillCell
                            cellImage = {adobephotoshopLogo}
                            cellHeading = "Adobe Photoshop"
                        />
                        <SkillCell
                            cellImage = {adobeillustratorLogo}
                            cellHeading = "Adobe Illustrator"
                        />
                        <SkillCell
                            cellImage = {adobepremiereLogo}
                            cellHeading = "Adobe Premiere Pro"
                        />
                    </div>

                    <div className="row" style={{"textAlign":"center"}}>
                        <h5>Project Management</h5>
                    </div>
                    <div className="row bg-blue-normal skillsRow">
                        <SkillCell
                            cellImage = {trelloLogo}
                            cellHeading = "Trello"
                        />
                        <SkillCell
                            cellImage = {githubLogo}
                            cellHeading = "Git & Github"
                        />
                        <SkillCell
                            cellImage = {hacknplanLogo}
                            cellHeading = "HacknPlan"
                        />
                        <SkillCell
                            cellImage = {googlesuiteLogo}
                            cellHeading = "Google Suite"
                        />
                    </div>

                    <div className="row" style={{"textAlign":"center"}}>
                        <h5>Programming Languages</h5>
                    </div>
                    <div className="row bg-blue-normal skillsRow">
                        <SkillCell
                            cellImage = {csharpLogo}
                            cellHeading = "C#"
                        />
                        <SkillCell
                            cellImage = {javascriptLogo}
                            cellHeading = "Javascript"
                        />
                        <SkillCell
                            cellImage = {rubyLogo}
                            cellHeading = "Ruby"
                        />
                        <SkillCell
                            cellImage = {html5Logo}
                            cellHeading = "HTML"
                        />
                        <SkillCell
                            cellImage = {css3Logo}
                            cellHeading = "CSS"
                        />
                    </div>

                    <div className="row" style={{"textAlign":"center"}}>
                        <h5>Programming Frameworks</h5>
                    </div>
                    <div className="row bg-blue-normal skillsRow">
                    <SkillCell
                            cellImage = {reactLogo}
                            cellHeading = "ReactJS"
                        />
                        <SkillCell
                            cellImage = {nodeLogo}
                            cellHeading = "Node & ExpressJS"
                        />

                        <SkillCell
                            cellImage = {rubyonrailsLogo}
                            cellHeading = "Ruby on Rails"
                        />
                    </div>
                    

                </div>
            </div>
            <div className="col s12 m4 l2">
                {/* Spacer column */}
            </div>
        

        </div>
    )
}