import firebase from 'firebase';
require('firebase/auth');

export const firebaseConfigBigfootDS = {
    apiKey: "AIzaSyCIiRLs18N5y04j1pSNTSfCH0DajpGetwY",
    authDomain: "bigfootdsservices.firebaseapp.com",
    databaseURL: "https://bigfootdsservices.firebaseio.com",
    projectId: "bigfootdsservices",
    storageBucket: "bigfootdsservices.appspot.com",
    messagingSenderId: "312438819152",
    appId: "1:312438819152:web:842a68ae7e6e76bcb18455"
};

export const firebaseConfigTBB = {
    apiKey: "AIzaSyC4CuWNf-egrMTHLGc4Rgn1L2ZiBLDWld4",
    authDomain: "thebestestbeehive.firebaseapp.com",
    databaseURL: "https://thebestestbeehive.firebaseio.com",
    projectId: "thebestestbeehive",
    storageBucket: "thebestestbeehive.appspot.com",
    messagingSenderId: "826639205916",
    appId: "1:826639205916:web:14ca1d4dddf583526a01d9",
    measurementId: "G-GCNPDBH0PH"
};
  
// Initialize Firebase with a default Firebase project
firebase.initializeApp(firebaseConfigBigfootDS);

// Initialize Firebase with a second Firebase project
const tbbProject = firebase.initializeApp(firebaseConfigTBB, "TBB");

console.log(firebase.app().name);  // "[DEFAULT]"
console.log(tbbProject.name);    // "otherProject"

// Use the shorthand notation to access the default project's Firebase services
export const bdsFirebaseStorage = firebase.storage();
export const bdsFirestore = firebase.firestore();

// Use the otherProject variable to access the second project's Firebase services
export const tbbFirebaseStorage = tbbProject.storage();
export const tbbFirestore = tbbProject.firestore();


export default {firebase, tbbProject}; // to access the TBB project
export const tbbAuth = tbbProject.auth();
export const bdsAuth = firebase.auth();
tbbAuth.useDeviceLanguage();
bdsAuth.useDeviceLanguage();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');
export const yahooProvider = new firebase.auth.OAuthProvider('yahoo.com');
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const emailProvider = new firebase.auth.EmailAuthProvider();
  
