import React, { Component } from "react";
import "./styles/App.css";
import "./styles/ThemeBigfootDS.css";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { PublicHome } from "./components/PublicHome";
import { windowPWAPreventer } from "./pwaHelpers";
import { BrowserRouter, Route } from "react-router-dom";
import SideNav from "./components/SideNav";
import Headroom from "react-headroom";
import Navbar from './components/pieces/Navbar';
import { ProvideAuth } from "./firebaseAccessor";


class App extends Component {
	constructor(props) {
		super(props);
		windowPWAPreventer();
		console.log("React app loading!!");
	}

	componentDidMount() {
		console.log("React app ready!");
	}

	render() {
		return (
			<div className="wrapper">
				<ProvideAuth>
					<BrowserRouter>
						{/* <!-- Sidebar  --> */}
						<SideNav />
						<div id="content" style={{ width: "100%" }}>
							{/* Top Navbar */}
							<Headroom>
								<Navbar />
							</Headroom>


							{/* Page content: */}
							<div className="pageRouting" style={{width:"100%"}}>
								<Route exact path="/" component={PublicHome} />
								{/* <Route exact path="/dev" component={DevHome} />
										<Route exact path="/presskit" component={Presskit} />
										<Route exact path="/platforms" component={Platforms} />
										<Route exact path="/platforms#vr" component={Platforms} />
										<Route exact path="/platforms#pc" component={Platforms} />
										<Route exact path="/platforms#consoles" component={Platforms} />
										<Route exact path="/forums" component={Forums} />
										<Route exact path="/support" component={Support} /> */}
							</div>
						</div>
					</BrowserRouter>
				</ProvideAuth>

			</div>
		);
	}
}

export default App;
