import React from 'react';


export default function SocialButton (props) {

    return (
        <a href={props.buttonLink} target="_blank" rel="noopener noreferrer">
            <div className="socialButton">
                <h4 >{props.buttonTitle}</h4>
            </div>
            <br></br>

        </a>
    )
}