import {
    bdsAuth,
    googleProvider,
    twitterProvider,
    microsoftProvider,
    yahooProvider,
    facebookProvider,
    tbbFirestore,
    bdsFirestore,
    tbbFirebaseStorage,
    bdsFirebaseStorage
} from "./firebaseConfig";

import React, { useState, useEffect, useContext, createContext } from 'react';

const authContext = createContext();




const bdsFirestoreContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}> {children} </authContext.Provider>
}

export function ProvideFirestoreBDS({ children }) {
    const firestoreBDS = useProvideFirestoreBDS();
    return <bdsFirestoreContext.Provider value={firestoreBDS}> {children} </bdsFirestoreContext.Provider>
}

export const useAuth = () => {
    return useContext(authContext);
}

export const useFirestoreBDS = () => {
    return useContext(bdsFirestoreContext);
}

function useProvideFirestoreBDS() {
    const [userFirestore, setUserFirestore] = useState(null);

    const createNewUserData = () => {
        bdsFirestore
            .collection('users')
            .doc(bdsAuth.currentUser.uid)
            .set({
                // 
            })
            .then((doc) => {
                if (doc.exists) {
                    console.log("Document data: ", doc.data());
                    setUserFirestore(doc.data());
                    return doc.data();
                }
            })
            .catch(error => {
                console.error(error);
                throw error;
            })
    }

    const getUserFirestoreData = () => {
        bdsFirestore
            .collection('users')
            .doc(bdsAuth.currentUser.uid)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    console.log("Document data: ", doc.data());
                    setUserFirestore(doc.data());
                    return doc.data();
                }
            })
            .catch(error => {
                console.error(error);
                throw error;
            })
    }

    return {
        getUserFirestoreData
    };
}

function useProvideAuth() {
    const [user, setUser] = useState(null);


    const logInWithGoogle = () => {
        return bdsAuth
            .signInWithPopup(googleProvider)
            .then(response => {
                setUser(response.user);
                return response.user;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    };

    const linkAccToGoogle = () => {
        return bdsAuth
            .currentUser
            .linkWithPopup(googleProvider)
            .then(result => {
                setUser(result.user);
                return result.user;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    const logInWithFacebook = () => {
        return bdsAuth
            .signInWithPopup(facebookProvider)
            .then(response => {
                setUser(response.user);
                return response.user;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    };

    const linkAccToFacebook = () => {
        return bdsAuth
            .currentUser
            .linkWithPopup(facebookProvider)
            .then(result => {
                setUser(result.user);
                return result.user;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    const logInWithTwitter = () => {
        return bdsAuth
            .signInWithPopup(twitterProvider)
            .then(response => {
                setUser(response.user);
                return response.user;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    };

    const linkAccToTwitter = () => {
        return bdsAuth
            .currentUser
            .linkWithPopup(twitterProvider)
            .then(result => {
                setUser(result.user);
                return result.user;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }


    const logInWithMicrosoft = () => {
        return bdsAuth
            .signInWithPopup(microsoftProvider)
            .then(response => {
                setUser(response.user);
                return response.user;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    };
    const linkAccToMicrosoft = () => {
        return bdsAuth
            .currentUser
            .linkWithPopup(microsoftProvider)
            .then(result => {
                setUser(result.user);
                return result.user;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    const logInWithYahoo = () => {
        return bdsAuth
            .signInWithPopup(yahooProvider)
            .then(response => {
                setUser(response.user);
                return response.user;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    };
    const linkAccToYahoo = () => {
        return bdsAuth
            .currentUser
            .linkWithPopup(yahooProvider)
            .then(result => {
                setUser(result.user);
                return result.user;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    const logInWithEmailPass = (email, password) => {
        return bdsAuth
            .signInWithEmailAndPassword(email, password)
            .then(response => {
                console.log(JSON.stringify(response));
                setUser(response.user);
                return response.user;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    };

    const signUpWithEmailPass = (email, password) => {
        return bdsAuth
            .createUserWithEmailAndPassword(email, password)
            .then(response => {
                setUser(response.user);
                return response.user;
            })
            .catch(error => {
                console.error(error);
                //throw error;
                logInWithEmailPass(email, password);
            });

    };

    const signOut = () => {
        return bdsAuth
            .signOut()
            .then(() => {
                setUser(false);
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    const sendPasswordResetEmail = email => {
        return bdsAuth
            .sendPasswordResetEmail(email)
            .then(() => {
                return true;
            });
    };


    const confirmPasswordReset = (code, password) => {
        return bdsAuth
            .confirmPasswordReset(code, password)
            .then(() => {
                return true;
            });
    };

    const updateUsername = (newUsername) => {
        var curUser = bdsAuth.currentUser;

        return curUser.updateProfile({
            displayName: newUsername
        }).then(() => {
            console.log("Success updating username!");
        }).catch(error => {
            console.log("Error updating username: \n" + error);
        })
    }

    const setRandomAvatar = async () => {
        var curUser = bdsAuth.currentUser;
        var chosenAvatar = await fetch('https://bigfootdsservices.web.app/api/v1/users/randomAvatar', {mode:'cors',})
                .then(async (response) => {
                    var text = await response.json();
                    console.log("This is the response.text: \n" + text); 
                    return text;
                })
                .then((data) => { 
                    console.log("This is the data obj: \n" + data.avatarURL); 
                    return data.avatarURL 
                })
                .catch((error) => {
                    console.log(error)
                });
        console.log('The chosen avatar is: ' + chosenAvatar);
        return curUser.updateProfile({
            photoURL: chosenAvatar
        }).then(() => {
            console.log("Success updating avatar!");
        }).catch(error => {
            console.log("Error updating avatar: \n" + error);
        })
    }



    useEffect(() => {
        const unsubscribe = bdsAuth.onAuthStateChanged(user => {
            if (user) {
                setUser(user);
            } else {
                setUser(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);


    return {
        user,
        logInWithEmailPass,
        signUpWithEmailPass,
        signOut,
        sendPasswordResetEmail,
        confirmPasswordReset,
        logInWithFacebook,
        logInWithGoogle,
        logInWithTwitter,
        logInWithMicrosoft,
        logInWithYahoo,
        linkAccToFacebook,
        linkAccToGoogle,
        linkAccToTwitter,
        linkAccToYahoo,
        linkAccToMicrosoft,
        updateUsername,
        setRandomAvatar
    };
}







